/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Navigate,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AdminApi from "src/api/admin";
import TeamToolbar from "src/components/team/TeamToolbar";
import TeamTab from "src/components/team/TeamTab";
import TransactionsTab from "src/components/team/TransactionsTab";
import TicketsTab from "src/components/team/TicketsTab";
import UsageTab from "src/components/team/UsageTab";
import PropertiesTab from "src/components/team/PropertiesTab";
import AccountTab from "src/components/team/AccountTab";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { TeamPageData } from "src/interfaces/TeamPageData";
import LogsTab from "src/components/team/LogsTab";

const TeamPage = () => {
  const { teamID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const teamIDNum = teamID && parseInt(teamID);
  const [team, setTeam] = useState<TeamPageData | null>(null);

  const fetchTeam = async () => {
    if (teamIDNum) {
      const response = await AdminApi.getTeam(teamIDNum);
      setTeam(response);
    }
  };

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  console.log({ tab });

  useEffect(() => {
    fetchTeam();
  }, [teamID, teamIDNum]);

  const getActiveTab = () => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab") || "Account";
    console.log("Current active tab:", tab); // Log the active tab
    return tab;
  };

  useEffect(() => {
    console.log("Location changed:", location.search); // Log when location changes
    getActiveTab(); // This will log the active tab whenever the location changes
  }, [location]);

  const handleTabChange = (tab: string) => {
    console.log("Changing tab to:", tab); // Log tab change
    navigate(`${location.pathname}?tab=${tab}`, { replace: true });
  };

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const renderActiveTab = () => {
    if (!team) return null;

    const activeTab = getActiveTab();
    console.log("Rendering active tab:", activeTab); // Log which tab is being rendered

    switch (activeTab) {
      case "Account":
        return <AccountTab team={team} fetchTeam={fetchTeam} />;
      case "Team":
        return <TeamTab team={team} />;
      case "Properties":
        return <PropertiesTab team={team} />;
      case "Usage":
        return <UsageTab team={team} />;
      case "Tickets":
        return <TicketsTab team={team} />;
      case "Transactions":
        return <TransactionsTab team={team} />;
      case "Logs":
        return <LogsTab team={team} />;
      default:
        console.log("Unknown tab:", activeTab); // Log if an unknown tab is encountered
        return null;
    }
  };

  return (
    <>
      {team && (
        <>
          <div className="mb-2">
            <TeamToolbar
              activeTab={getActiveTab()}
              onTabChange={handleTabChange}
            />
          </div>
          <div>{renderActiveTab()}</div>
        </>
      )}
    </>
  );
};

export default TeamPage;
